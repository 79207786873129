import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { NCLoadingIndicator } from '@daupler/nexus-components';
import { useNavigate } from 'react-router-dom';
import api from './api';
import IncidentList from './views/session/IncidentList';
import { Navbar } from './components/Navbar';
import './Session.css';
import {
  FormInformation,
  IncidentFormWizard,
} from './views/session/IncidentFormWizard';
import queryClient from './queryClient';
import { SessionContext } from './contexts/SessionContext';

function Session() {
  const navigate = useNavigate();
  const { linkToken, sessionToken } = useParams<RouterParams>();
  const { isLoading, data, error } = useQuery(
    ['sessionData'],
    async () => {
      const response = await api.loadSessionData(linkToken, sessionToken);
      return response.data;
    },
    {
      refetchInterval: 1000 * 15,
    },
  );

  const getDispatchSuggestions = async (input: FormInformation) => {
    const response = await api.classifyIncident({
      linkToken,
      sessionToken,
      ...input,
    });
    return response.data;
  };

  const mutation = useMutation(api.dispatchIncident);
  const createIncident = async (input: Partial<CreateIncidentInput>) => {
    const response = await mutation.mutateAsync({
      linkToken,
      sessionToken,
      description: input.description,
      location: input.location,
      name: input.name,
      phoneNumber: input.phoneNumber,
      phoneExtension: input.phoneExtension,
      handlerId: input.handlerId,
      urgent: input.urgent,
      datums: input.datums,
    });
    queryClient.setQueryData(['sessionData'], response.data);
  };

  if (error) {
    const errorCode = (error as any).response.data[0];
    navigate(`/${linkToken}`, { state: errorCode });
  }

  return (
    <SessionContext.Provider value={data}>
      <div className="dispatch-session">

        <div className="dispatch-session__navbar">
          <Navbar />
          <div className="dispatch-session__navbar__loading">
            {isLoading && <NCLoadingIndicator blockUi label="Loading" />}
          </div>
        </div>

        <div className="dispatch-session__form">
          <IncidentFormWizard
            api={api}
            data={data}
            onSubmitFormInformation={getDispatchSuggestions}
            onSubmit={createIncident}
          />
        </div>

        <div className="dispatch-session__content">
          <IncidentList />
        </div>
      </div>
    </SessionContext.Provider>
  );
}

export default Session;
